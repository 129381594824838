import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {

  public blogs = [
    {
      name: 'Europe 2013',
      link: 'http://scullintravels.tumblr.com/',
      image: './assets/images/europe-2013.jpg'
    },
    {
      name: 'Scullivers Travels 2014',
      link: 'http://travelsscullin2014.tumblr.com/',
      image: './assets/images/scullivers-travels-2014.jpg'
    },
    {
      name: 'Scandals In Scandinavia 2015',
      link: 'http://scandalsinscandinavia.tumblr.com/',
      image: './assets/images/scandals-in-scandinavia-2015.png'
    },
    {
      name: 'Scullinis In Sicilia 2016',
      link: 'http://travelsscullin.tumblr.com/',
      image: './assets/images/scullinis-in-sicilia-2016.jpg'
    },
    {
      name: 'Ireland Spain Portugal 2017',
      link: 'https://scullintravels2017.tumblr.com/',
      image: './assets/images/ireland-spain-portugal-2017.jpg'
    },
    {
      name: 'Germany Yah 2018',
      link: 'http://scullinsngermany18.tumblr.com/',
      image: './assets/images/germany-yah-2018.jpg'
    }
  ]
  constructor(
  ) { }

  ngOnInit(){

  }

}
